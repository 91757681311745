import React from 'react';

const Modal = ({open, onClose}) => {
if(!open) return null

  return (
    <div className='overlay'>
        <p onClick={onClose}>X</p>
       <p>autoprefixer: end value has mixed support, consider using flex-end instead</p>
    </div>
  )
}


export default Modal;