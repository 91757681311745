import React from 'react'
import './LowerContact.css'
import lowerfooteLogos from '../../assets/img/home/lowerfooteLogos.png'
// import getademo from '../../assets/img/home/Get a Demo.png'
import { useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';

function LowerContact() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_vtnpthd', 'template_q1siuvk', form.current, 'IwPgWCCbEPvBXeJbO')
      .then((result) => {
        alert("Message Sent Successfully !")
    }, (error) => {
        console.log(error.text);
    });
    e.target.reset();
  };

  return (
    <div>
      <p className='heading-lower-contact' data-aos="fade-up"
        data-aos-duration="1000">98% of our ads are delivered to individuals,<br></br> not to cookies.</p>
      <div className='lower-conten-box'>
        <div className='lower-content-left'>
          <div data-aos="fade-up"
            data-aos-duration="1000" data-aos-delay="400">9MO tracks post-click conversions on an advertiser’s site by appending privacy-friendly IDs through cross-channel targeting. With <strong>Connected TV Advertising</strong>, we carry out accurate measurement and attribution of post-click conversions, allowing advertisers to gain valuable insights into the effectiveness of their campaigns while prioritizing user privacy.</div>
          <div><img data-aos="fade-up"
            data-aos-duration="1000" data-aos-delay="400" src={lowerfooteLogos} id='lowfootimg'></img></div>
        </div>
        <div className='lower-conter-right' data-aos="fade-up"
          data-aos-duration="1000" data-aos-delay="400">
          <div className='get-a-demo-border'>
            <div className='get-a-demo-form'>

              <form ref={form} onSubmit={sendEmail}>
                <p id='get-1'>Get a Demo</p>
                <p>Email</p>
                <input name="email" id="email" type="email" required></input>
                <p>Message</p>
                <input name="message" id="message" type="text"></input>
                <p>Service</p>
                <select name="service" id="service">
                  <option>--Select--</option>
                  <option value="Advertise">Advertise</option>
                  <option value="Monetize">Monetize</option>
                </select>
                <br></br>
                <button style={{cursor:"pointer"}} type='submit' className='contact-submit-form'>Submit</button>
                <p className='lower-text-contact'>To learn how Clearbit handles your information, please see our <a href='#'>privacy policy.</a></p>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LowerContact