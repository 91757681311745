import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../HeroSlider/HeroSlider.css'
import waving from '../../assets/img/home/Yuv2.png'
import image30 from '../../assets/img/home/TV.png'
import image15 from '../../assets/img/home/Dooh-mockup2.png'
import image16 from '../../assets/img/home/programmatic_adv.webp'
import image17 from '../../assets/img/home/mobile_mock_img.webp'
import image18 from '../../assets/img/home/audio_devices_img.webp'

const HeroSlider = () => {
  const sliderSettings = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    adaptiveHeight: true,
    pauseOnHover:false
  };

  return (
    <Slider {...sliderSettings}>
      {/* Slide 0 */}
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" id='arr2'>
      <div data-aos="fade-up"
                data-aos-duration="1000" data-aos-delay="100" id='arr2'>
                <div id='arr2-1'><h2><strong>Connected TV Advertising</strong> - Built for the Future </h2></div>
                <div id="container1">
                    {/* <canvas id="waves" style={{height:"400px !important"}}></canvas> */}
                    <div id="back0">
                        <img src={waving} alt='' id="back11" />
                        <img src={waving} alt='' id="back12" />
                    </div>
                </div>
                <img data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="100" src={image30} alt='' id="arr2-3" />
            </div>
      </div>

      {/* Slide 1 */}
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" id='arr2'>
      <div data-aos="fade-up"
                data-aos-duration="1000" data-aos-delay="100" id='arr2'>
                <div id='arr2-1'><h2><strong>DOOH Advertising</strong> - Hypertarget Your Audience </h2></div>
                <div id="container1">
                    {/* <canvas id="waves" style={{height:"400px !important"}}></canvas> */}
                    <div id="back0">
                        <img src={waving} alt='' id="back11" />
                        <img src={waving} alt='' id="back12" />
                    </div>
                </div>
                <img data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="100" src={image15} alt='' id="arr2-3" />
            </div>
      </div>

      {/* Slide 2 */}
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" id='arr2'>
      <div data-aos="fade-up"
                data-aos-duration="1000" data-aos-delay="100" id='arr2'>
                <div id='arr2-1'><h2><strong>Programmatic Advertising</strong> - Higher Reach </h2></div>
                <div id="container1">
                    {/* <canvas id="waves" style={{height:"400px !important"}}></canvas> */}
                    <div id="back0">
                        <img src={waving} alt='' id="back11" />
                        <img src={waving} alt='' id="back12" />
                    </div>
                </div>
                <img data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="100" src={image16} alt='' id="arr2-3" />
            </div>
      </div>

      {/* Slide 3 */}
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" id='arr2'>
      <div data-aos="fade-up"
                data-aos-duration="1000" data-aos-delay="100" id='arr2'>
                <div id='arr2-1'><h2><strong>Cross Device Targeting</strong> - Be Present When Needed </h2></div>
                <div id="container1">
                    {/* <canvas id="waves" style={{height:"400px !important"}}></canvas> */}
                    <div id="back0">
                        <img src={waving} alt='' id="back11" />
                        <img src={waving} alt='' id="back12" />
                    </div>
                </div>
                <img data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="100" src={image17} alt='' id="arr2-3" />
            </div>
      </div>

      {/* Slide 4 */}
      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" id='arr2'>
      <div data-aos="fade-up"
                data-aos-duration="1000" data-aos-delay="100" id='arr2'>
                <div id='arr2-1'><h2><strong>Audio Advertising</strong> - The New Era Of Advertising </h2></div>
                <div id="container1">
                    {/* <canvas id="waves" style={{height:"400px !important"}}></canvas> */}
                    <div id="back0">
                        <img src={waving} alt='' id="back11" />
                        <img src={waving} alt='' id="back12" />
                    </div>
                </div>
                <img data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="100" src={image18} alt='' id="arr2-3" />
                </div>
      </div>
    </Slider>
  );
};

export default HeroSlider;
