import React from 'react'
import { Link } from "react-router-dom";
import Footer from '../../components/Footer/Footer';
import ContactHeader from '../../components/ContactHeader/ContactHeader';
import PhoneNumberValidation from '../../components/PhoneNumberValidation';
import './Contact.css';
import back from '../../assets/img/home/Yuv2.png'
import { useRef ,useState } from 'react';
import emailjs from '@emailjs/browser';


const Contact = () => {

  const [selectedOption, setSelectedOption] = useState('');
  const [showAdditionalInput, setShowAdditionalInput] = useState(false);
  const [additionalInputValue, setAdditionalInputValue] = useState('');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
    setShowAdditionalInput(event.target.value === 'other'); // Show input field if 'other' is selected
  };

  const handleAdditionalInputChange = (event) => {
    setAdditionalInputValue(event.target.value);
  };

  const form=useRef();
  const sendEmail = (e) => {

    e.preventDefault();

  //  emailjs.sendForm('service_961sa57', 'template_hams89v', form.current, '9bUL6Fn-cC39aB-A0')
    emailjs.sendForm('service_vtnpthd', 'template_0ult8j2', form.current, 'IwPgWCCbEPvBXeJbO')
      .then((result) => {
          alert("Message Sent Successfully !")
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };
  document.title="Contact | 9mediaOnline";

  return (
    <div>
      <ContactHeader />
      <div className='form-container-contact-parent'>
      <div id="back-light1">
          <img src={back} alt='' id="back1" />
          <img src={back} alt='' id="back2" />

        </div>
        <div className='contact-form-section'>

          <div className='contact-form-img-sec'>
            <div className='first-text'>
              <h1 className='head-head1'>GET IN TOUCH</h1>
              <h3 className='headText-light1'>Ready to enter the new era of TV advertising?</h3>
              <div className='contact-meeting'><Link to="https://bit.ly/48UAe78" target="_blank">Schedule Meet</Link></div>
            </div>
            <div className='second-img'>
              <img src='images/contactBgBody.png'></img>
            </div>
          </div>
          <div className='contact-form-sec'>
          <div className='contat-form-container'>
          <div className='form-contact-child'>
            <form ref={form} onSubmit={sendEmail}>
          <h4>Get a Demo</h4>
          <input type="text" name="name" id="name" placeholder='Name' required></input>
          <input type="email" name="email" id="email" placeholder='Work Email' required></input>
          {/*<input type="number" name="phone" id="phone"  required></input>*/}
          <PhoneNumberValidation />
          <input type="text" name='companyName' id='companyName' placeholder='Company' required></input>

          <select name="service" id="service" value={selectedOption} onChange={handleSelectChange}>
            <option>Select Service</option>
            <option value="Advertiser">Advertiser</option>
            <option value="Publisher">Publisher</option>
            <option value="other">Other</option>
          </select>
          <br></br>
          {showAdditionalInput && (
            <div>
              <br></br>
              <input
                type="text"
                name='additional'
                placeholder="Please enter your service:"
                value={additionalInputValue}
                onChange={handleAdditionalInputChange}
              />
            </div>
          )}
         {/*  <select name="service" id="service">
            <option>Select Service</option>
            <option value="OTT Advertisement">OTT Advertisement</option>
            <option value="CTV Advertisement">CTV Advertisement</option>
            <option value="Display 360 Advertisement">Display 360 Advertisement</option>
            <option value="Search 360 Advertisement">Search 360 Advertisement</option>
            <option value="Programmatic Advertising">Programmatic Advertising</option>
            <option value="ROI Driven Advertisement">ROI Driven Advertisement</option>
            <option value="Brand Awareness Advertisement">Brand Awareness Advertisement</option>
            <option value="Retargeting Advertising">Retargeting Advertising</option>
            <option value="Influencer Advertisement">Influencer Advertisement</option>
            <option value="Others">Others</option>
          </select>
          <p>Company Type</p>
          <input type="text" name='companyType' id='companyType' required></input>
          <p>Role</p>
          <input type="text" name='Role' id='type' required></input>
          <p>Country</p>
          <input type="text" name='country' id='country' required></input> 
          <p>Message</p>
          <input type="text" name='hearAboutUs' id='hearAboutUs' required></input>
          <p>Want to stay in touch with us?</p>
          <select name="stayConnect" id="stayConnect">
            <option value="yes">YES</option>
            <option value="no">NO</option>
          </select>*/}
          <br></br>
          <button style={{cursor:"pointer"}} type='submit' className='contact-submit-form'>Submit</button>
          <h6>To learn how 9Media Online handles your information, please see our <a href='#'>privacy policy</a>.</h6>
          </form>
        </div>
        </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contact