import React from 'react'
import Header from '../components/Header'
import '../components/header.css'
import '../pages/home.css'
// import image1 from '../assets/img/home/TV.png'
import image2 from '../assets/img/home/Group76.png'
import image3 from '../assets/img/home/1.png'
import image4 from '../assets/img/home/hbo_logo_img.webp'
import image5 from '../assets/img/home/3.png'
import image6 from '../assets/img/home/sha_logo_img.png'
import image7 from '../assets/img/home/5.png'
import image8 from '../assets/img/home/spotify_logo_img.png'
import image9 from '../assets/img/home/news_logo_img.png'
import image10 from '../assets/img/home/nyp_logo_img.png'
import image11 from '../assets/img/home/twp_logo_img.png'
import image12 from '../assets/img/home/hulu_logo_img.png'
import image13 from '../assets/img/home/11.png'
import image14 from '../assets/img/home/meta_logo_img.png'
import tv from '../assets/img/home/add_tv.svg'
import adswork from '../assets/img/home/ads_work.svg'
import dooh from '../assets/img/home/dooh_img_icon.svg'
import arvr from '../assets/img/home/arvr_icon.svg'
// import lock from '../assets/img/home/lock.png'
// import cash from '../assets/img/home/cash.png'
// import hamburger from '../assets/img/home/hamburger.png'
// import overflow from '../assets/img/home/071.png'
import privateMarket from '../assets/img/home/PrivateMarketplace.png'
import timeline from '../assets/img/home/verticalLine.png'
import t2016 from '../assets/img/home/2007.svg'
import t2017 from '../assets/img/home/2009.svg'
import t2018 from '../assets/img/home/2011.svg'
import t2019 from '../assets/img/home/2012.svg'
import t2020 from '../assets/img/home/2014.svg'
import t2021 from '../assets/img/home/2016_1.svg'
import t2024 from '../assets/img/home/2018_1.svg'
import t2026 from '../assets/img/home/2021_1.svg'
import circle_out from '../assets/img/home/Ellipse3.png'
import logo1 from '../assets/img/home/MaskGroup.png'
// import Testimonial from '../components/Testimonial/Testimonial'
import HeroSlider from '../components/HeroSlider/HeroSlider'
import sidebg from '../../src/assets/img/home/side-bg.svg'
import noungoal from '../../src/assets/img/home/noun-goal-icon.svg'
import nounstrategy from '../../src/assets/img/home/noun-strategy-icon.svg'
import nouncreativity from '../../src/assets/img/home/noun-creativity-icon.svg'
import nounexecution from '../../src/assets/img/home/noun-execution-icon.svg'
import nounoptimize from '../../src/assets/img/home/noun-optimize-icon.svg'
import nounsuccess from '../../src/assets/img/home/noun-success-icon.svg'
import count178 from '../../src/assets/img/home/178+M.svg'
import year15 from '../../src/assets/img/home/15+years.svg'
import brand498 from '../../src/assets/img/home/498+brand.svg'
import waving from '../../src/assets/img/home/Yuv2.png'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from '../components/Footer/Footer';
import LowerContact from '../components/Lowecontact/LowerContact';
import { Helmet } from 'react-helmet'


AOS.init()
function Home() {
    return (
        <>
            {/* <Header text1="Privacy and Performance" text2=" By Design" subtext1="Connected TV advertising made simple" buttonText="GET STARTED" /> */}

            <Header text0="Future-First " text1=" Advertising" subtext1="Seamless, Immersive, Revolutionary" showButtons={true} buttonText1="Schedule Call" buttonText2="Download Media Kit"/>
            
            <Helmet>
                <title>Connected TV Advertising Agency - 9MediaOnline</title>
                <meta name="description" content="Drive measurable conversions, app installs, website visits, salesI etc with Connected TV Advertising. We offer 360* Digital Advertising and Marketing services." />
            </Helmet>
            <HeroSlider />
                
                <div id='arr2-1'><h3>The Evolution of <strong>Full Funnel Advertising</strong></h3>
                <div id='arr2-2'>
                    <div data-aos="fade-up"
                        data-aos-duration="1500" data-aos-delay="200" id='arr2-2-1'><h3>Are You Prepared To Reach Your Customers?</h3></div>
                    <div data-aos="fade-up"
                        data-aos-duration="1500" data-aos-delay="300" id='arr2-2-2'>At 9 Media, we are not just keeping up with the future of marketing;
                        we are paving the path for an Advertising haven. Our method is based on
                        identifying your customers across channels using data, where immersive storytelling,
                        cutting-edge technology, and true brand-audience interactions intersect.
                        Join us and help define the future together.</div>
                </div>
                <div data-aos="fade-up"
                        data-aos-duration="1000" data-aos-delay="350">
                            <button  onClick={() => window.location.href = '/contact'} id='arr8-2-2-2'>CONNECT NOW</button>
                        </div>
                
                    </div>

           

            <div data-aos="fade-up"
                data-aos-duration="500" id='arr3'>
                <div data-aos="fade-up"
                    data-aos-duration="1000" data-aos-delay="0" id='arr3-1'>THE ONES WHO BELIEVE IN US</div>
                <div id='arr3-2'>
                    <img id='arr3-2-1' src={image2} alt="" />
                    <img id='arr3-2-2' src={image2} alt="" />
                </div>
            </div>

            <div id="arr4">
                <div id='arr4-1'>
                    <div data-aos="fade-up"
                        data-aos-duration="1500" data-aos-delay="100" id='arr4-1-1'>CHANNELS</div>
                    <div data-aos="fade-up"
                        data-aos-duration="1500" data-aos-delay="300" id='arr4-1-2'><h2>Scale Your Advertising Reach And Get Help For<strong> Your Full Funnel Strategy With Zero Efforts.</strong></h2></div>
                    <div data-aos="fade-up"
                        data-aos-duration="1500" data-aos-delay="500" id='arr4-1-3'>At 9mediaonline, we understand the challenges you face: reaching engaged audiences, building brand loyalty, and driving measurable results in a dynamic marketing landscape.</div>
                    <div data-aos="fade-up"
                        data-aos-duration="1500" data-aos-delay="500" id='arr4-1-3'>That's why we offer a <strong>comprehensive suite of advertising solutions</strong> specifically designed for today's forward-thinking CMOs, Account Managers,and Brand Managers.</div>

                    <div data-aos="fade-up"
                        data-aos-duration="1500" data-aos-delay="500" id='arr4-1-3'>From Programmatic Display, CTV/OTT, DOOH,  Facebook, Instagram, Youtube, Reddit, Snapchat, our Advertising Solutions cater to your objectives.</div>
                </div>
                <div id='arr4-2'>
                    <img data-aos="fade-up"
                        data-aos-duration="1000" data-aos-delay="300" id='arr4-2-1' src={image3} alt='' />
                    <img data-aos="fade-up"
                        data-aos-duration="1000" data-aos-delay="350" id='arr4-2-2' src={image4} alt='' />
                    <img
                        data-aos="fade-up"
                        data-aos-duration="1500" data-aos-delay="350" id='arr4-2-3' src={image5} alt='' />
                    <img data-aos="fade-up"
                        data-aos-duration="1600" data-aos-delay="400" id='arr4-2-4' src={image6} alt='' />
                    <img
                        data-aos="fade-up"
                        data-aos-duration="1800" data-aos-delay="400" id='arr4-2-5' src={image7} alt='' />
                    <img
                        data-aos="fade-up"
                        data-aos-duration="2000" data-aos-delay="400" id='arr4-2-6' src={image8} alt='' />
                    <img
                        data-aos="fade-up"
                        data-aos-duration="2100" data-aos-delay="500" id='arr4-2-7' src={image9} alt='' />
                    <img
                        data-aos="fade-up"
                        data-aos-duration="2500" data-aos-delay="500" id='arr4-2-8' src={image10} alt='' />
                    <img
                        data-aos="fade-up"
                        data-aos-duration="2600" data-aos-delay="500" id='arr4-2-9' src={image11} alt='' />
                    <img
                        data-aos="fade-up"
                        data-aos-duration="2700" data-aos-delay="600" id='arr4-2-10' src={image12} alt='' />
                    <img
                        data-aos="fade-up"
                        data-aos-duration="2900" data-aos-delay="600" id='arr4-2-11' src={image13} alt='' />
                    <img
                        data-aos="fade-up"
                        data-aos-duration="3000" data-aos-delay="600" id='arr4-2-12' src={image14} alt='' />
                </div>

            </div>
            {/* <button onClick={()=>window.location.href='/contact'} id='book-a-free'>Book a free Consultation</button> */}



            <div id='arr5'>
                <div id='arr5-1'>
                    <img id='sidebg' src={sidebg} alt="" />
                    <div id='arr5-1-1'>

                        <div className='div-flex' id='arr5-1-1-1'>

                            <img data-aos="fade-up"
                                data-aos-duration="1000" data-aos-delay="100" className='img-block' src={tv} alt='' />

                            <span data-aos="fade-up"
                                data-aos-duration="2000" data-aos-delay="200" className='head-small'><h4> Addressable TV:</h4>Your brand is the story; make it a legend in the living room, we’re rewriting the playbook of audience connection. Tailored ads, laser-focused targeting to different devices – we’re here to make sure your brand doesn’t just show up on your audience tv, it creates recall value.</span>
                        </div>
                        {/* <div id='arr5-1-1-3'></div> */}
                    </div>
                    <div id='arr5-1-1'>

                        <div className='div-flex' id='arr5-1-1-1'>

                            <img data-aos="fade-up"
                                data-aos-duration="1000" data-aos-delay="100" className='img-block' src={adswork} alt='' />

                            <span data-aos="fade-up"
                                data-aos-duration="2000" data-aos-delay="200" className='head-small'><h4> Programmatic Ads That Work Smarter</h4>Our platform integrates AI and machine learning to weave a seamless narrative across multiple consumer touchpoints,
                                It goes beyond merely pushing ads; meticulously crafts evolving stories that span across these varied channels,
                                drawing consumers into a continuous, immersive
                                narrative journey.
                                </span>
                        </div>
                        {/* <div id='arr5-1-1-3'></div> */}
                    </div>
                    <div id='arr5-1-1'>

                        <div className='div-flex' id='arr5-1-1-1'>

                            <img data-aos="fade-up"
                                data-aos-duration="1000" data-aos-delay="100" className='img-block' src={dooh} alt='' />

                            <span data-aos="fade-up"
                                data-aos-duration="2000" data-aos-delay="200" className='head-small'><h4> Programmatic DOOH Dominance</h4>The world’s our billboard, and with programmatic DOOH, we’re painting it with precision. It’s all about context—right message, right place, right time. We provide different formats like Static DOOH, Dynamic DOOH,
                                Programmatic DOOH.
                                </span>
                        </div>
                        {/* <div id='arr5-1-1-3'></div> */}
                    </div>
                    
                    <div id='arr5-1-1'>

                        <div className='div-flex' id='arr5-1-1-1'>

                            <img data-aos="fade-up"
                                data-aos-duration="1000" data-aos-delay="100" className='img-block' src={arvr} alt='' />

                            <span data-aos="fade-up"
                                data-aos-duration="2000" data-aos-delay="200" className='head-small'><h4> Immersive Experience Design (AR/VR)</h4>Creating virtual and augmented reality experiences that allow consumers to interact with brands in a completely immersive environment. This ecosystem would include virtual showrooms, interactive product stories, and gamified ad content that is informative and entertaining.
                                </span>
                        </div>
                        {/* <div id='arr5-1-1-3'></div> */}
                    </div>
                    
                </div>
                <div id='arr5-2'>
                    <div id='arr5-2-1'>
                        <div id='arr5-2-1-1'>
                            <span data-aos="fade-up"
                                data-aos-duration="1000" data-aos-delay="200"><h2>Advertising  Made Simple:  Across All  Channels.  </h2>
                                </span>
                                <h3><strong>We Echo The Same Message:</strong></h3>
                        </div>

                    </div>

                </div>
            </div>

            <div data-aos="fade-up"
                data-aos-duration="1000" id='arr6'>
                <div id='arr6-1'>
                    {/* <img src={overflow} alt='' id='arr6-img' /> */}
                    <div id='arr6-1-1-1' data-aos="fade-up"
                        data-aos-duration="1000" data-aos-delay="400">Ideation <strong>To Execution</strong></div>
                    <div id='arr6-1-1'>
                        <div id='arr6-1-1-2'>
                            <div id='arr5-1-1'>
                        <div className='div-flex-dark' id='arr5-1-1-1'>

                            <img data-aos="fade-up"
                                data-aos-duration="1000" data-aos-delay="100" className='img-block' src={noungoal} alt='' />

                            <span data-aos="fade-up"
                                data-aos-duration="2000" data-aos-delay="200" className='head-small'><h4> 1. Vision Alignment:</h4>Dive deep into your brand’s goals and values to ensure our content aligns perfectly with your ethos.</span>
                        </div>
                        <div className='div-flex-dark' id='arr5-1-1-1'>

                            <img  data-aos="fade-up"
                                data-aos-duration="1000" data-aos-delay="100" className='img-block' style={{marginLeft : 12}} src={nouncreativity} alt='' />

                            <span data-aos="fade-up"
                                data-aos-duration="2000" data-aos-delay="200" className='head-small'><h4> 3. Creative Ideation:</h4>Transform strategic insights into compelling narratives and visuals that captivate and engage your audience.</span>
                        </div>
                        <div className='div-flex-dark' id='arr5-1-1-1'>

                            <img data-aos="fade-up"
                                data-aos-duration="1000" data-aos-delay="100" className='img-block' src={nounexecution} alt='' />

                            <span data-aos="fade-up"
                                data-aos-duration="2000" data-aos-delay="200" className='head-small'><h4> 5. Real-Time Optimization:</h4>Monitor and refine campaigns continuously for peak performance and maximize ROI.</span>
                        </div>
                    </div>
                        </div>
                        <div id='arr6-1-1-3'>
                            
                        <div className='div-flex-dark' id='arr5-1-1-1'>

                            <img data-aos="fade-up"
                                data-aos-duration="1000" data-aos-delay="100" className='img-block' src={nounstrategy} alt='' />

                            <span data-aos="fade-up"
                                data-aos-duration="2000" data-aos-delay="200" className='head-small'><h4> 2. Strategic Planning:</h4>Craft a tailored strategy that places your message in front of the right eyes, using the most effective channels and methods.</span>
                        </div>

                        <div className='div-flex-dark' id='arr5-1-1-1'>

                            <img data-aos="fade-up"
                                data-aos-duration="1000" data-aos-delay="100" className='img-block' src={nounoptimize} alt='' />

                            <span data-aos="fade-up"
                                data-aos-duration="2000" data-aos-delay="200" className='head-small'><h4> 4. Efficient Execution: </h4>Launch campaigns with precision, utilizing advanced tech and programmatic solutions for smooth, targeted delivery.
                                </span>
                        </div>

                        <div className='div-flex-dark' id='arr5-1-1-1'>

                            <img data-aos="fade-up"
                                data-aos-duration="1000" data-aos-delay="100" className='img-block' src={nounsuccess} alt='' />

                            <span data-aos="fade-up"
                                data-aos-duration="2000" data-aos-delay="200" className='head-small'><h4> 6. Delivering Impact:</h4>Achieve beyond expectations with results that redefine success for your brand.</span>
                        </div>
                        </div>
                    </div>


                    <div id='arr6-2'>
                            <img data-aos="fade-up"
                                data-aos-duration="1000" data-aos-delay="100" src={count178} alt='' id='arr6-2-img' />
                             <img data-aos="fade-up"
                                data-aos-duration="1000" data-aos-delay="100" src={year15} style={{marginTop : 190}} alt='' id='arr6-2-img' />
                                <img data-aos="fade-up"
                                data-aos-duration="1000" data-aos-delay="100" src={brand498} style={{marginTop : 390}} alt='' id='arr6-2-img' />

                    </div>
                </div>

            </div>
            {/* <div id='arr7' data-aos="fade-up"
                data-aos-duration="1000">
                <div id='arr7-1'>TESTIMONIALS</div>
                <div id='arr7-2'>The Word of Trust</div>
            </div>
            <Testimonial /> */}

            <div data-aos="fade-up"
                data-aos-duration="1000" id='arr8-0'>How did we get here?</div>
            <div id='arr8'>
                <div id='arr8-1'>
                    <div id='timeline-left'>
                        <img data-aos="fade-up"
                            data-aos-duration="500" data-aos-delay="300" id='t2016' className='relative' src={t2016} alt='' draggable='false' />
                        <img data-aos="fade-up"
                            data-aos-duration="500" data-aos-delay="500" id='t2018' className='relative' src={t2018} alt='' draggable='false' />
                        <img data-aos="fade-up"
                            data-aos-duration="500" id='t2020' className='relative' data-aos-delay="2500" src={t2020} alt='' draggable='false' />
                        <img data-aos="fade-up"
                            data-aos-duration="1000" id='t2024' className='' data-aos-delay="3000" src={t2024} alt='' draggable='false' />
                    </div>
                    <img src={timeline} alt='' draggable='false' />
                    <div id='timeline-right'>
                        <img data-aos="fade-up"
                            data-aos-duration="500" data-aos-delay="1000" id='t2017' className='relative' src={t2017} alt='' draggable='false' />
                        <img data-aos="fade-up"
                            data-aos-duration="500" id='t2019' className='relative' data-aos-delay="2000" src={t2019} alt='' draggable='false' />
                        <img data-aos="fade-up"
                            data-aos-duration="500" id='t2021' className='relative' data-aos-delay="3000" src={t2021} alt='' draggable='false' />
                        <img data-aos="fade-up"
                            data-aos-duration="500" id='t2026' className='relative' data-aos-delay="3000" src={t2026} alt='' draggable='false' />
                    </div>
                </div>
                <div id='arr8-2'>
                    <div id='arr8-2-1'>
                        <img src={circle_out} alt='' id='arr8-img1' className='img-absolute' />
                        <img src={circle_out} alt='' id='arr8-img2' className='img-absolute' />
                        <img src={circle_out} alt='' id='arr8-img3' className='img-absolute' />
                        <img src={logo1} alt='' id='arr8-img4' className='img-absolute' />
                    </div>
                    <div id='arr8-2-2'>
                        <div id='arr8-2-2-1'>
                            <h3>Our Purpose </h3>
                            <h4><strong>Forge. Spark. Unite.</strong></h4>
                            </div>

                        <div id='arr8-2-2-3'>We’re driven by the vision to transform how brands and audiences interact in a digital-first world, ensuring every campaign we create not only captures attention
                            but also enriches lives.

                        </div>

                        <button onClick={() => window.location.href = '/contact'} id='arr8-2-2-2'>Know More</button>
                    </div>


                </div>
            </div>
            <LowerContact />
            <Footer />

        </>
    )
}

export default Home
