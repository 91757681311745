import React from 'react'
import AOS from 'aos';
AOS.init()

const Ads = () => {
  document.title="Ads | 9mediaOnline";
  return (
    <div>
      Hi
    </div>
  )
}

export default Ads