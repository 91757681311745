import React from 'react'
import AOS from 'aos';
AOS.init()

const AppAds = () => {
  document.title="Ads | 9mediaOnline";
  return (
    <div>
      Hii
    </div>
  )
}

export default AppAds